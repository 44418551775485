<template>
  <div id="sampleRequisition">
    <el-dialog
      :title="sampleRequisitionFormTitle"
      width="1200px"
      :visible.sync="sampleRequisitionDialogVisible"
      :close-on-click-modal="false"
      @close="sampleRequisitionDialogClose"
    >
      <el-form
        ref="sampleRequisitionFormRef"
        :model="sampleRequisitionForm"
        :rules="sampleRequisitionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="serialNumber">
              <el-input
                v-model="sampleRequisitionForm.serialNumber"
                placeholder="请输入编号"
                clearable
                :disabled="sampleRequisitionFormTitle !== '新增样品领用单'
                  && sampleRequisitionFormTitle !== '修改样品领用单'
                  && sampleRequisitionFormTitle !== '样品领用单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期" prop="applyDate">
              <el-date-picker
                v-model="sampleRequisitionForm.applyDate"
                placeholder="请选择申请日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收费情况" prop="fee">
              <el-input
                v-model="sampleRequisitionForm.fee"
                placeholder="请输入收费情况"
                clearable
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称" prop="customerName">
              <el-select
                v-model="sampleRequisitionForm.customerName"
                placeholder="请选择客户"
                clearable
                filterable
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.customerName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="新客户" prop="newCustomer">
              <el-radio-group
                v-model="sampleRequisitionForm.newCustomer"
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              >
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="2">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model="sampleRequisitionForm.address"
                placeholder="请输入详细地址"
                clearable
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="contactPerson">
              <el-input
                v-model="sampleRequisitionForm.contactPerson"
                placeholder="请输入联系人"
                clearable
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="telephone">
              <el-input
                v-model="sampleRequisitionForm.telephone"
                placeholder="请输入联系电话"
                clearable
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发货期限" prop="deliveryPeriod">
              <el-date-picker
                v-model="sampleRequisitionForm.deliveryPeriod"
                placeholder="请选择发货期限"
                value-format="yyyy-MM-dd"
                :disabled="
                  sampleRequisitionFormTitle !== '新增样品领用单' &&
                    sampleRequisitionFormTitle !== '修改样品领用单' &&
                    sampleRequisitionFormTitle !== '样品领用单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="
              sampleRequisitionFormTitle === '新增样品领用单' ||
                sampleRequisitionFormTitle === '修改样品领用单'
            "
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column field="productNo" title="产品编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择产品"
                  clearable
                  filterable
                  @change="materialProductStockChange(row)"
                >
                  <el-option
                    v-for="item in materialProductStockList"
                    :key="item.id"
                    :label="item.productNo"
                    :value="item.productNo"
                  />
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="sampleName"
              title="样品名称"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="quantity"
              title="计划领用数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="
                sampleRequisitionFormTitle === '新增样品领用单' ||
                  sampleRequisitionFormTitle === '修改样品领用单'
              "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="仓库管理员" prop="warehouseManagerResult">
          <el-radio-group
            v-model="sampleRequisitionForm.warehouseManagerResult"
            :disabled="
              sampleRequisitionFormTitle !== '仓库管理员' &&
                sampleRequisitionFormTitle !== '样品领用单详情'
            "
          >
            <el-radio :label="1">
              通过
            </el-radio>
            <el-radio :label="2">
              不通过
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="sampleRequisitionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="sampleRequisitionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.serialNumber" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input
          v-model="searchForm.customerName"
          placeholder="请输入客户名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SAMPLE_REQUISITION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="sampleRequisitionPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column label="申请日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applyDate">{{
            scope.row.applyDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="fee" label="收费情况" />
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column label="新客户">
        <template slot-scope="scope">
          <span v-if="scope.row.newCustomer === 1">是</span>
          <span v-if="scope.row.newCustomer === 2">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="详细地址" />
      <el-table-column prop="contactPerson" label="联系人" />
      <el-table-column prop="telephone" label="联系电话" />
      <el-table-column prop="deliveryPeriod" label="发货期限" />
      <el-table-column prop="receiver" label="领用人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.requisitionDate">{{
            scope.row.requisitionDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="warehouseManager" label="仓库管理员" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.warehouseManagerDate">{{
            scope.row.warehouseManagerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结果">
        <template slot-scope="scope">
          <span v-if="scope.row.warehouseManagerResult === 1">通过</span>
          <span v-if="scope.row.warehouseManagerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['SAMPLE_REQUISITION_DELETE']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['SAMPLE_REQUISITION_UPDATE']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['SAMPLE_REQUISITION_ADMIN']) &&
                scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleAdmin(scope.$index, scope.row)"
          >
            仓库管理员
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="scope.row.status === 2"
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="getPrint(scope.$index, scope.row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="sampleRequisitionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSampleRequisition,
  deleteSampleRequisition,
  updateSampleRequisition,
  selectSampleRequisitionInfo,
  selectSampleRequisitionList,
  adminSampleRequisition
} from '@/api/sale/sampleRequisition'
import { selectCustomerList } from '@/api/sale/notice'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'

export default {
  data () {
    return {
      sampleRequisitionDialogVisible: false,
      sampleRequisitionFormTitle: '',
      sampleRequisitionForm: {
        id: '',
        serialNumber: '',
        applyDate: '',
        fee: '',
        customerName: '',
        newCustomer: '',
        address: '',
        contactPerson: '',
        telephone: '',
        deliveryPeriod: '',
        warehouseManagerResult: '',
        status: '',
        detailJson: '',
        taskId: ''
      },
      materialProductStockList: [],
      sampleRequisitionFormRules: {
        serialNumber: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}],
        customerName: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      sampleRequisitionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        customerName: ''
      },
      detailList: [],
      customerList: []
    }
  },
  created () {
    selectMaterialProductStockList({ type: 1 }).then(res => {
      this.materialProductStockList = res.list
    })
    selectSampleRequisitionList(this.searchForm).then((res) => {
      this.sampleRequisitionPage = res
    })
    selectCustomerList().then((res) => {
      this.customerList = res
    })
  },
  methods: {
    sampleRequisitionDialogClose () {
      this.$refs.sampleRequisitionFormRef.resetFields()
      this.detailList = []
    },
    sampleRequisitionFormSubmit () {
      if (this.sampleRequisitionFormTitle === '样品领用单详情') {
        this.sampleRequisitionDialogVisible = false
        return
      }
      this.$refs.sampleRequisitionFormRef.validate(async (valid) => {
        if (valid) {
          if (this.sampleRequisitionFormTitle === '新增样品领用单') {
            this.sampleRequisitionForm.id = ''
            this.sampleRequisitionForm.status = 1
            this.sampleRequisitionForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addSampleRequisition(this.sampleRequisitionForm)
          } else if (this.sampleRequisitionFormTitle === '修改样品领用单') {
            this.sampleRequisitionForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateSampleRequisition(this.sampleRequisitionForm)
          } else if (this.sampleRequisitionFormTitle === '仓库管理员') {
            this.sampleRequisitionForm.status = 2
            await adminSampleRequisition(this.sampleRequisitionForm)
          }
          this.sampleRequisitionPage = await selectSampleRequisitionList(this.searchForm)
          this.sampleRequisitionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.sampleRequisitionFormTitle = '新增样品领用单'
      this.sampleRequisitionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSampleRequisition(row.id)
        if (
          this.sampleRequisitionPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.sampleRequisitionPage = await selectSampleRequisitionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.sampleRequisitionFormTitle = '修改样品领用单'
      this.sampleRequisitionDialogVisible = true
      this.selectSampleRequisitionInfoById(row.id)
    },
    handleAdmin (index, row) {
      this.sampleRequisitionFormTitle = '仓库管理员'
      this.sampleRequisitionDialogVisible = true
      this.selectSampleRequisitionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.sampleRequisitionFormTitle = '样品领用单详情'
      this.sampleRequisitionDialogVisible = true
      this.selectSampleRequisitionInfoById(row.id)
    },
    getPrint (index, row) {
      this.$router.push({ path: '/print3', query: { id: row.id }})
    },
    selectSampleRequisitionInfoById (id) {
      selectSampleRequisitionInfo(id).then(res => {
        this.sampleRequisitionForm.id = res.id
        this.sampleRequisitionForm.serialNumber = res.serialNumber
        this.sampleRequisitionForm.applyDate = res.applyDate
        this.sampleRequisitionForm.fee = res.fee
        this.sampleRequisitionForm.customerName = res.customerName
        this.sampleRequisitionForm.newCustomer = res.newCustomer
        this.sampleRequisitionForm.address = res.address
        this.sampleRequisitionForm.contactPerson = res.contactPerson
        this.sampleRequisitionForm.telephone = res.telephone
        this.sampleRequisitionForm.deliveryPeriod = res.deliveryPeriod
        this.sampleRequisitionForm.warehouseManagerResult = res.warehouseManagerResult
        this.sampleRequisitionForm.status = res.status
        this.sampleRequisitionForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSampleRequisitionList(this.searchForm).then((res) => {
        this.sampleRequisitionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSampleRequisitionList(this.searchForm).then((res) => {
        this.sampleRequisitionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSampleRequisitionList(this.searchForm).then((res) => {
        this.sampleRequisitionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (
        this.checkPermission(['SAMPLE_REQUISITION_ADMIN'])
        && row.status === 1
      ) {
        return 'review'
      }
      return ''
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find((item) => item.customerName === value)
        console.log(customer)
        this.sampleRequisitionForm.address = customer.receivingAddress
        this.sampleRequisitionForm.contactPerson = customer.contactPerson
        this.sampleRequisitionForm.telephone = customer.phone
      } else {
        this.sampleRequisitionForm.address = ''
        this.sampleRequisitionForm.contactPerson = ''
        this.sampleRequisitionForm.telephone = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialProductStockChange (row) {
      if (row.productNo) {
        let materialProductStock = this.materialProductStockList.find(item => item.productNo === row.productNo)
        row.sampleName = materialProductStock.productName
        row.spec = materialProductStock.spec
      } else {
        row.sampleName = ''
        row.spec = ''
      }
    }
  }
}
</script>

<style>
</style>
